import axios from 'axios';
import { toast } from 'react-toastify';
// import { toast } from 'react-toastify';

const axiosClient = axios.create({
  mode: 'no-cors',
  // baseURL: 'http://66.42.62.216/api',
  // baseURL: 'http://192.100.100.110:9090/api',
  // baseURL: 'http://127.0.0.1:9090/api',
  // baseURL: 'http://10.8.0.13:9090/api',
  // baseURL: process.env.REACT_APP_API_URL_LOCAL,
  baseURL: process.env.REACT_APP_API_URL_GLOBAL,
  headers: {
    'content-type': 'application/json'
  }
  // paramsSerializer: (params) => qs.stringify(params, {encode: false})
});

axiosClient.interceptors.request.use(async (config) => {
  const customHeaders = {};

  const accessToken = localStorage.getItem('token');
  if (accessToken) {
    customHeaders.Authorization = 'Bearer ' + accessToken;
  }
  config.headers['Content-Type'] = 'application/json';
  config.headers['Access-Control-Allow-Origin'] = '*';
  config.headers['Access-Control-Allow-Methods'] = 'GET,PUT,POST,DELETE,PATCH,OPTIONS';

  // config.paramsSerializer = {
  //   encode: (params) => QueryString.stringify(params, {arrayFormat: 'repeat'})
  // };

  return {
    ...config,
    headers: {
      ...customHeaders, // auto attach token
      ...config.headers // but you can override for some requests
    }
  };
});

axiosClient.interceptors.response.use(
  (response) => {
    if (response && response.data) {
      return response.data;
    }

    return response;
  },
  (error) => {
    // throw error;
    // console.log('ádfasf', error);
    if (error.response.status === 401) {
      // handle error: inform user, go to login, etc
      window.localStorage.removeItem('token');
      // window.location.href = '/pages/login';
    } else if (error.response.status === 403) {
      toast.error('Bạn không có quyền thực hiện');
    } else if (error.response.status === 400) {
      // console.log('ádfasf', error);
      toast.error(error.response.data.errorMessage, {
        position: toast.POSITION.TOP_RIGHT
      });
    } else {
      toast.error(error.response.data.errorMessage, {
        position: toast.POSITION.TOP_RIGHT
      });
      return Promise.reject(error);
    }
  }
);

export default axiosClient;
