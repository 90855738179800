import axiosClient from 'api/axiosClient';

const loginApi = {
  getLogin: (params) => {
    const url = '/user/login';
    return axiosClient.post(url, params);
  },
  getUser: () => {
    const url = '/api/general/user';
    return axiosClient.get(url);
  }
};

export default loginApi;
