import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : {},
  token: '',
  loading: false,
  errorMessage: '',
  isLogin: window.localStorage.getItem('token') ? true : false,
  userLoading: false,
  userMessage: '',
  roles: window.localStorage.getItem('roles') ? JSON.parse(window.localStorage.getItem('roles')) : []
};

const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginActions(state) {
      state.loading = true;
    },
    loginSuccess(state, action) {
      state.loading = false;
      state.isLogin = true;
      state.token = action.payload.token;
      state.user = action.payload.userInfo;
      state.roles = action.payload.roles;
    },
    loginFail(state, action) {
      state.loading = false;
      state.errorMessage = action.payload;
    },

    logoutAction(state) {
      state.user = {};
      state.isLogin = false;
    },

    changePass(state) {
      state.isLogin = false;
    },

    //user
    getUser(state) {
      state.userLoading = true;
    },
    getUserSuccess(state, action) {
      state.userLoading = false;
      state.user = action.payload;
      state.roles = action.payload.roles;
    },
    getUserFail(state, action) {
      state.userLoading = false;
      state.userMessage = action.payload;
    }
  }
});

export const { getUserSuccess, getUserFail, loginActions, loginSuccess, loginFail, logoutAction, changePassword, changePass, getUser } =
  AuthSlice.actions;
export default AuthSlice.reducer;
