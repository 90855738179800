import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line no-unused-vars
import { ThemeProvider } from '@mui/material/styles';
// eslint-disable-next-line no-unused-vars
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
// eslint-disable-next-line no-unused-vars
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
// eslint-disable-next-line no-unused-vars
import NavigationScroll from 'layout/NavigationScroll';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { ability, updateAbility } from './config/ability.js';
import types from 'config/types.js';

// ==============================|| APP ||============================== //

const App = () => {
  const customization = useSelector((state) => state.customization);

  const isLogin = useSelector((state) => state.auth.isLogin);
  // const ability = useContext(AbilityContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const [checkedRoles, setCheckedRoles] = useState(false);

  useEffect(() => {
    if (isLogin) {
      updateAbility(ability, JSON.parse(localStorage.getItem('roles')));
    }
  }, [isLogin, ability]);

  useEffect(() => {
    if (isLogin) {
      const roles = JSON.parse(localStorage.getItem('roles'));
      const currentPath = localStorage.getItem('currentPath');
      // Kiểm tra vai trò và điều hướng người dùng đến trang tương ứng
      if (currentPath) {
        navigate(currentPath);
      } else {
        if (roles && roles.some((item) => item === 'ROLE_KE_TOAN_KHO' || item === 'ROLE_ADMIN')) {
          navigate('/');
          // console.log('sadfsf 1');
        } else if (roles && roles.some((item) => item === 'ROLE_CAN_BO_QTHT')) {
          // navigate('/deviceDepartment/device');
          console.log('sadfsf 2');
        } else if (roles && roles.some((item) => item === 'ROLE_USER')) {
          // navigate('/upload/uploadFile');
          console.log('sadfsf 3');
        }
      }
    } else {
      dispatch({ type: types.LOGOUT });
    }
  }, [isLogin]);

  useEffect(() => {
    if (isLogin) {
      localStorage.setItem('currentPath', location.pathname);
    }
  }, [location.pathname]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        <NavigationScroll>
          <Routes />
        </NavigationScroll>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
