import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase, Typography } from '@mui/material';

// project imports
import config from 'config';
import Logo from 'ui-component/Logo';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
  <ButtonBase disableRipple component={Link} to={config.defaultPath}>
    {/* <Logo /> */}
    <img src="/images/PB1.png" alt="Logo" width="48" height="48" />
    {/* <Typography sx={{ fontSize: '3rem' }}>LOGO</Typography> */}
  </ButtonBase>
);

export default LogoSection;
