const types = {
  //LOGIN
  LOGIN: 'LOGIN',
  LOGIN_FAIL: 'LOGIN_FAIL',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGOUT: 'LOGOUT',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',

  //upload file
  UPLOAD_FILE_SCAN: 'UPLOAD_FILE_SCAN',
  FILE_SCAN_DETAIL: 'FILE_SCAN_DETAIL',

  //Promissory
  GET_RECEIPT: 'GET_RECEIPT',
  GET_RECEIPT_ID: 'GET_RECEIPT_ID',
  GET_RECEIPT_DEVICES_ID: 'GET_RECEIPT_DEVICES_ID',
  GET_RECEIPT_DEVICES_SELECT: 'GET_RECEIPT_DEVICES_SELECT',
  UPDATE_RECEIPT_ID: 'UPDATE_RECEIPT_ID',
  CREATE_RECEIPT: 'CREATE_RECEIPT',
  DOWLOAD_FILE_RECEIPT: 'DOWLOAD_FILE_RECEIPT',

  //GET_ORGANIZATION_Đơn vị

  GET_ORGANIZATION: 'GET_ORGANIZATION',

  //Department

  GET_DEPARTMENT: 'GET_DEPARTMENT',
  CREATE_DEPARTMENT: 'CREATE_DEPARTMENT',
  GET_DEPARTMENT_ID: 'GET_DEPARTMENT_ID',
  UPDATE_DEPARTMENT_ID: 'UPDATE_DEPARTMENT_ID',

  //USER
  GET_USER: 'GET_USER',
  GET_USERS: 'GET_USERS',
  GET_USER_ROLE: 'GET_USER_ROLE',
  GET_USER_ID: 'GET_USER_ID',
  UPDATE_USER_ID: 'UPDATE_USER_ID',
  CREATE_USER: 'CREATE_USER',

  // DEVICES_CATEGORY

  GET_CATEGORY_DEVICE: 'GET_CATEGORY_DEVICE',
  GET_CATEGORY_DEVICE_ID: 'GET_CATEGORY_DEVICE_ID',
  UPDATE_CATEGORY_DEVICE_ID: 'UPDATE_CATEGORY_DEVICE_ID',
  CREATE_CATEGORY_DEVICE: 'CREATE_CATEGORY_DEVICE',
  GET_DEVICES_CATEGORY_ALL: 'GET_DEVICES_CATEGORY_ALL',

  //DEVICE

  GET_DEVICES_CATEGORY: 'GET_DEVICES_CATEGORY',
  GET_DEVICE_CATEGORY_ID: 'GET_DEVICE_CATEGORY_ID',
  GET_PROMISSORY_DEVICES_ID: 'GET_PROMISSORY_DEVICES_ID',
  UPDATE_DEVICE_ID: 'UPDATE_DEVICE_ID',
  CREATE_DEVICE: 'CREATE_DEVICE',

  //exports
  GET_EXPORTS: 'GET_EXPORTS',
  GET_EXPORT_ID: 'GET_EXPORT_ID',
  GET_EXPORT_DEVICES_ID: 'GET_EXPORT_DEVICES_ID',
  UPDATE_EXPORT_ID: 'UPDATE_EXPORT_ID',
  CREATE_EXPORT: 'CREATE_EXPORT',
  DOWLOAD_FILE_DELIVERY: 'DOWLOAD_FILE_DELIVERY',

  //inventory

  GET_INVENTORY: 'GET_INVENTORY',
  GET_INVENTORY_ID: 'GET_INVENTORY_ID',
  UPDATE_INVENTORY_ID: 'UPDATE_INVENTORY_ID',
  DOWLOAD_FILE_INVENTORY: 'DOWLOAD_FILE_INVENTORY',
  FILE_DOWNLOAD: 'FILE_DOWNLOAD',

  //DEVICE HISTORY

  GET_DEVICE_HISTORY: 'GET_DEVICE_HISTORY',
  GET_DEVICES_HISTORY_ID: 'GET_DEVICES_HISTORY_ID',
  UPDATE_DEVICE_HISTORY_ID: 'UPDATE_DEVICE_HISTORY_ID',
  CREATE_DIVICE_HISTORY: 'CREATE_DIVICE_HISTORY',
  DELETE_DEVICE_HISTORY: 'DELETE_DEVICE_HISTORY',
  FILE_DOWNLOAD_HISTORY: 'FILE_DOWNLOAD_HISTORY',
  FILE_HISTORY_REPAIR: 'FILE_HISTORY_REPAIR',

  //warehouse
  GET_WAREHOUSE_QT: 'GET_WAREHOUSE_QT',
  GET_WAREHOUSE_QT_ID: 'GET_WAREHOUSE_QT_ID',
  UPDATE_WAREHOUSE_QT_ID: 'UPDATE_WAREHOUSE_QT_ID',
  CREATE_WAREHOUSE_QT: 'CREATE_WAREHOUSE_QT',
  GET_WAREHOUSE_QT_REPORT: 'GET_WAREHOUSE_QT_REPORT',

  //dowload file

  DOWLOAD_FILE: 'DOWLOAD_FILE',
  FILE_DOWNLOAD_PUBLIC: 'FILE_DOWNLOAD_PUBLIC'
};

export default types;
