import { call, fork, put, take, takeEvery, takeLatest } from 'redux-saga/effects';

import types from 'config/types';
import fileApi from 'api/file';
import { fileScanDetailAcsess, fileScanDetailLoading } from './reducer';

function* uploadFileScan(action) {
  try {
    // yield put(deviceHistoryLoading());
    const response = yield fileApi.uploadFileScan(action.payload);

    if (response) {
      console.log('response', response);
    }
  } catch (error) {
    console.log('errrr', error);
    // toast.error(, {
    // 	position: toast.POSITION.TOP_RIGHT
    // });
  }
}

function* fileScanDetail(action) {
  try {
    yield put(fileScanDetailLoading());
    const resspone = yield fileApi.fileScanDetail(action.payload);
    if (resspone) {
      yield put(fileScanDetailAcsess(resspone));
    }
  } catch (error) {}
}

export function* watchFile() {
  yield takeLatest(types.UPLOAD_FILE_SCAN, uploadFileScan);
  yield takeLatest(types.FILE_SCAN_DETAIL, fileScanDetail);
}
