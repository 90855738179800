import { all } from 'redux-saga/effects';
import { watchAuth } from 'views/pages/authentication/authentication3/service/sagaLogin';
// import { watchCategoryDevice } from 'views/pages/category/categorydevice/service/categorydeviceSaga';
// import { watchDepartment } from 'views/pages/category/departments/service/sagaDepartment';
// import { watchDevice } from 'views/pages/category/devices/service/sagaDevice';

// import { watchUsers } from 'views/pages/category/user/service/sagaUser';
// import { watchDeviceHistory } from 'views/pages/device_department/service/devicesSaga';
import { watchFile } from 'views/pages/fileScan/service/saga';

export default function* rootSaga() {
  yield all([
    watchAuth(),
    watchFile()
    // watchDevice(),
    // watchDepartment(),
    // watchUsers(),
    // watchCategoryDevice(),
    // watchDeviceHistory(),
    // watchFile()
  ]);
}
