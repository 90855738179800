import { call, fork, put, take, takeLatest } from 'redux-saga/effects';
// import types from 'config/types';
// import { getRoles } from '../../../../help/help';
// import { history } from '../../../../utils/history/history';
// import entities from './../../../../config/entities';
import { loginActions, loginFail, loginSuccess, logoutAction, changePass, getUser, getUserSuccess } from './loginSilce';
import types from 'config/types';
import loginApi from 'api/login';

function* login(action) {
  try {
    yield put(loginActions());
    let response = yield loginApi.getLogin(action.payload);
    if (response) {
      window.localStorage.setItem('token', response.token);
      window.localStorage.setItem('user', JSON.stringify(response.userInfo));
      window.localStorage.setItem('roles', JSON.stringify(response.roles));

      yield put(loginSuccess(response));
    }
  } catch (error) {
    yield put(loginFail(error.response.data.errorMessage));
  }
}

function* logout() {
  // console.log('logout');
  window.localStorage.removeItem('token');
  window.localStorage.removeItem('user');
  window.localStorage.removeItem('roles');
  window.localStorage.removeItem('sibar_active');
  window.localStorage.removeItem('valueInit');
  window.localStorage.removeItem('currentPath');
  yield put(logoutAction());
  // history.push('/login');
}

// function* changePassword(action) {
// 	const { payload } = action;

// 	try {
// 		let data = yield AuthApi.postChangePassword(payload);
// 		// console.log('data', data.status);
// 		if (data.status === 200) {
// 			window.localStorage.removeItem('token');

// 			yield put(changePass());
// 		} else {
// 			// yield put(actions.CHANGE_PASSWORD_FAIL(Errors.handle(data)));
// 		}
// 	} catch (error) {
// 		// yield put(actions.CHANGE_PASSWORD_FAIL(Errors.handle(error)));
// 		console.log('error password');
// 	}
// }

function* watchLoginFlow() {
  while (true) {
    const isToken = Boolean(window.localStorage.getItem('token'));
    // console.log('test');
    if (!isToken) {
      const action = yield take(types.LOGIN);
      // console.log('testdfsgsgs');

      yield fork(login, action);
    }
    yield take([types.LOGOUT, loginFail().type, changePass().type]);
    yield call(logout);
  }
}

function* user(action) {
  try {
    yield put(getUser());
    const res = yield loginApi.getUser();
    if (res) {
      console.log('user', res);
      yield put(getUserSuccess(res));
    } else {
      // console.log('test res');
    }
  } catch (error) {
    console.log('error', error);
  }
}

// function* watchUser() {
// 	// yield takeLatest(types.CHANGE_PASSWORD, changePassword);
// 	yield takeLatest(types.GET_USERS, user);
// }

export function* watchAuth() {
  yield fork(watchLoginFlow);
  // yield fork(watchUser);
}
