import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, Button, ButtonBase, Menu, Typography, styled } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';

// assets
import { IconMenu2 } from '@tabler/icons';
import FlexBetween from 'ui-component/betweenflex/FlexBoxBetween';
import { IconSunLow, IconMoonStars } from '@tabler/icons';
import { Link, useNavigate } from 'react-router-dom';
import entities from 'config/entities';
import { useDispatch, useSelector } from 'react-redux';
import types from 'config/types';
import { useMemo } from 'react';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const ItemMenu = styled(Typography)(({ theme }) => ({
  /* Sử dụng các thuộc tính từ theme trong styled component */
  color: theme.palette.primary.dark,
  fontWeight: 700,
  cursor: 'pointer',
  '&:hover': {
    color: theme.palette.text.dark
  }
  // fontSize: theme.typography.subtitle1.fontSize
  // Thêm các thuộc tính khác từ theme tại đây
}));

const ItemMenuA = styled('a')(({ theme }) => ({
  /* Sử dụng các thuộc tính từ theme trong styled component */
  color: theme.palette.primary.dark,
  fontWeight: 700,
  cursor: 'pointer',
  '&:hover': {
    color: theme.palette.text.dark
  },
  textDecoration: 'none'
  // fontSize: theme.typography.subtitle1.fontSize
  // Thêm các thuộc tính khác từ theme tại đây
}));

const Header = ({ handleLeftDrawerToggle }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isLogin = useSelector((state) => state.auth.isLogin);
  const roles = useSelector((state) => state.auth.roles);

  const hanldeSingIn = () => {
    navigate('/pages/login');
  };

  const hanldeSingOut = () => {
    dispatch({ type: types.LOGOUT });
  };

  const handleNavigate = (item) => {
    if (item === 'SETTING') {
      navigate('/setting/service');
    }
  };

  const handleHistoryPage = () => {
    navigate('/history');
  };

  const rolesMemo = useMemo(() => {
    const rolesDetail = roles.some((item) => item === 'ROLE_ADMIN');

    return rolesDetail;
  }, [roles]);

  return (
    <FlexBetween sx={{ justifyContent: 'space-between', width: '100%' }}>
      {/* logo & toggler button */}
      <FlexBetween
        sx={{
          // width: '100%',
          // [theme.breakpoints.down('md')]: {
          //   width: 'auto'
          // }
          justifyContent: 'space-between',
          gap: '1rem'
        }}
      >
        <FlexBetween gap="1.2rem" sx={{ flexGrow: 2, justifyContent: 'flex-start' }}>
          <Box component="div">
            <LogoSection />
          </Box>

          <FlexBetween gap="1rem">
            {isLogin && <ItemMenu onClick={() => handleHistoryPage()}>Lịch sử</ItemMenu>}
            {/* <ItemMenu>Graph</ItemMenu>
          <ItemMenu>Graph</ItemMenu> */}
            <ItemMenuA href="http://103.107.183.157:9090/swagger-ui.html#/" target="_blank">
              API
            </ItemMenuA>
            {isLogin && rolesMemo && <ItemMenu onClick={() => handleNavigate('SETTING')}>Cài đặt</ItemMenu>}
          </FlexBetween>
        </FlexBetween>
        {/* button menu */}

        {/* <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: 'all .2s ease-in-out',
              background: theme.palette.secondary.light,
              color: theme.palette.secondary.dark,
              '&:hover': {
                background: theme.palette.secondary.dark,
                color: theme.palette.secondary.light
              }
            }}
            onClick={handleLeftDrawerToggle}
            color="inherit"
          >
            <IconMenu2 stroke={1.5} size="1.3rem" />
          </Avatar>
        </ButtonBase> */}
        {/* <SearchSection /> */}
      </FlexBetween>

      {/* header search */}

      {/* notification & profile */}
      {/* <NotificationSection /> */}
      <FlexBetween gap="0.5rem" sx={{ justifyContent: 'flex-end' }}>
        {/* <ButtonBase sx={{ borderRadius: '12px' }}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: 'all .2s ease-in-out',
              background: theme.palette.secondary.light,
              color: theme.palette.secondary.dark,
              '&[aria-controls="menu-list-grow"],&:hover': {
                background: theme.palette.secondary.dark,
                color: theme.palette.secondary.light
              }
            }}
            color="inherit"
          >
            <IconMoonStars stroke={1.5} size="1.3rem" />
          </Avatar>
        </ButtonBase> */}
        {!isLogin ? (
          <Button variant="contained" sx={{ borderRadius: '20px 20px' }} onClick={() => hanldeSingIn()}>
            Đăng nhập
          </Button>
        ) : (
          <Button sx={{ borderRadius: '20px 20px' }} variant="contained" onClick={() => hanldeSingOut()}>
            Đăng xuất
          </Button>
        )}
        {/* <ProfileSection /> */}
      </FlexBetween>
    </FlexBetween>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func
};

export default Header;
