import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  fileUpload: []
};

const homepageSlice = createSlice({
  name: 'homepage',
  initialState,
  reducers: {
    handleUploadFile: (state, action) => {
      state.fileUpload = action.payload;
    }
  }
});

export const { handleUploadFile } = homepageSlice.actions;

export default homepageSlice.reducer;
