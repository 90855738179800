const entities = {
  login: '/login',

  login3: '/pages/login',
  register3: '/pages/register',

  //setting

  setting: '/setting',

  dashboard: '/dashboard/default',
  dashboarDetail: '/dashboard/detail/:id',

  //Kho - TỒn kho

  inventory: '/warehouse/inventory',

  private_scan: '/authen/homepage',

  inventory_supplies: '/warehouse/inventory/supplies',
  inventory_stationery: '/warehouse/inventory/stationery',
  inventory_asset: '/warehouse/inventory/asset',

  //Kho - Đã xuất

  exported: '/warehouse/exported',

  exported_supplies: '/warehouse/exported/supplies',
  exported_stationery: '/warehouse/exported/stationery',
  exported_asset: '/warehouse/exported/asset',

  //Phiếu

  promissory: 'warehouse/promissory',
  createPromissory: 'warehouse/promissory/create',
  updatePromissory: 'warehouse/promissory/:id',

  devices: 'category/devices',

  //user
  units: '/category/unit',
  createUnit: '/category/unit/create',
  updateUnit: '/category/unit/:id',

  //organization
  organizations: '/category/organization',
  createOrganization: '/category/organization/:id/create',
  updateOrganization: '/category/organization/:id',

  //department
  departments: '/category/organization/:id',
  createDepartment: '/category/organization/:id/create',
  updateDepartment: '/category/organization/:id/:departmentId',

  add: '/category/add',

  //Văn phòng phẩm - stationery

  categoryDevice: '/category/categoryDevice',
  createStationery: '/category/device/stationery/create',
  updateStationery: '/category/device/stationery/:id',
  //supplies
  supplies: '/category/device/supplies',
  createSupplies: '/category/device/supplies/create',
  updateSupplies: '/category/device/supplies/:id',
  //asset
  asset: '/category/device/asset',
  createAsset: '/category/device/asset/create',
  updateAsset: '/category/device/asset/:id',
  //user
  users: '/category/users',
  createUsers: '/category/user/create',
  updateUsers: '/category/user/:id',

  //deviceDepartment

  deviceDepartment: 'deviceDepartment/device',
  createDeviceDepartment: 'deviceDepartment/device/create',
  updateDeviceDepartment: 'deviceDepartment/device/:id',
  deviceDepartmentReport: 'deviceDepartment/device/report',

  requestExport: 'deviceDepartment/requestExport',
  //uploadFile

  uploadFile: 'upload/uploadFile'

  // dashboardDefault: '/job',

  // administrator: '/administrator',
  // work: '/work',

  // tableBasic: '/tables/tbl-basic',
  // tableDense: '/tables/tbl-dense',

  // utilsTypography: '/utils/util-typography',
  // utilsColor: '/utils/util-color',
  // utilsShadow: '/utils/util-shadow',
  // utilsTablerIcons: '/icons/tabler-icons',
  // utilsMaterialIcons: '/icons/material-icons',

  // samplePage: '/sample-page'
};

export default entities;
