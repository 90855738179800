import axiosClient from 'api/axiosClient';

const fileApi = {
  //dowload file
  uploadFileScan: (params) => {
    console.log('params', params);
    const url = `/files/scan`;
    return axiosClient.post(url, params);
  },
  fileScanDetail: (params) => {
    const url = `files/${params.id}/scanresult`;

    return axiosClient.get(url);
  }
};

export default fileApi;
