import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import authReducer from 'views/pages/authentication/authentication3/service/loginSilce.js';
import homepageReducer from 'views/pages/homepage/service/reducer.js';
import fileReducer from 'views/pages/fileScan/service/reducer';
// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  customization: customizationReducer,
  auth: authReducer,
  homepage: homepageReducer,
  file: fileReducer
});

export default reducer;
