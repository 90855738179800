// assets
import { IconDashboard } from '@tabler/icons';

// constant
const icons = { IconDashboard };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const setting = {
  id: 'setting',
  title: 'Setting',
  type: 'group',
  children: [
    {
      id: 'service',
      title: 'Service',
      type: 'item',
      url: '/setting/service',
      icon: icons.IconDashboard,
      breadcrumbs: false
    },
    {
      id: 'scanning',
      title: 'Scanning',
      type: 'item',
      url: '/setting/scanning',
      icon: icons.IconDashboard,
      breadcrumbs: false
    },
    {
      id: 'update',
      title: 'Update',
      type: 'item',
      url: '/setting/update',
      icon: icons.IconDashboard,
      breadcrumbs: false
    },
    {
      id: 'logging',
      title: 'Logging',
      type: 'item',
      url: '/setting/logging',
      icon: icons.IconDashboard,
      breadcrumbs: false
    },
    {
      id: 'password',
      title: 'Password',
      type: 'item',
      url: '/setting/password',
      icon: icons.IconDashboard,
      breadcrumbs: false
    }
  ]
};

export default setting;
