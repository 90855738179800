import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import Login from 'views/pages/authentication/authentication3/Login3';
import { Navigate } from 'react-router';

//homepage
const HomePage = Loadable(lazy(() => import('views/pages/homepage/HomePage')));
const FileScan = Loadable(lazy(() => import('views/pages/fileScan/FileScan')));
const Setting = Loadable(lazy(() => import('views/pages/setting/Setting')));
const Service = Loadable(lazy(() => import('views/pages/setting/Service')));
const Scanning = Loadable(lazy(() => import('views/pages/setting/Scanning')));
const History = Loadable(lazy(() => import('views/pages/history/History')));

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

const Auth = Loadable(lazy(() => import('views/pages/auth/AuthPage')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    // {
    //   path: '/pages/login',
    //   element: <Login />
    // },
    {
      path: '/homepage',
      element: <HomePage />
    },
    {
      path: '/file/:id',
      element: <FileScan />
    },
    {
      element: <Auth allowedRoles={['ROLE_ADMIN', 'ROLE_USER']} />,
      path: '/',
      children: [
        {
          path: '/authen/homepage',
          element: <HomePage />
        },
        {
          path: '/history',
          element: <History />
        },
        {
          path: '/authen/file/:id',
          element: <FileScan />
        },
        {
          path: '/setting/service',
          element: <Service />
        },
        {
          path: '/setting/scanning',
          element: <Scanning />
        },

        {
          path: '/',
          element: <Navigate to="authen/homepage" replace />
        }
      ]
    }
  ]
};
// {
//   path: '/',
//   element: <DashboardDefault />
// }
// {
//   path: 'dashboard',
//   children: [
//     {
//       path: 'default',
//       element: <DashboardDefault />
//     }
//   ]
// },
// {
//   path: 'utils',
//   children: [
//     {
//       path: 'util-typography',
//       element: <UtilsTypography />
//     }
//   ]
// },
// {
//   path: 'utils',
//   children: [
//     {
//       path: 'util-color',
//       element: <UtilsColor />
//     }
//   ]
// },
// {
//   path: 'utils',
//   children: [
//     {
//       path: 'util-shadow',
//       element: <UtilsShadow />
//     }
//   ]
// },
// {
//   path: 'icons',
//   children: [
//     {
//       path: 'tabler-icons',
//       element: <UtilsTablerIcons />
//     }
//   ]
// },
// {
//   path: 'icons',
//   children: [
//     {
//       path: 'material-icons',
//       element: <UtilsMaterialIcons />
//     }
//   ]
// },
// {
//   path: 'sample-page',
//   element: <SamplePage />
// }
// ]
// };

export default MainRoutes;
