import setting from './setting';
import pages from './pages';
import utilities from './utilities';
import other from './other';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [
    setting
    // pages, utilities, other
  ]
};

export default menuItems;
