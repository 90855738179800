// import { addDays, format, parseISO } from 'date-fns';

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
  fileScanDetail: {}
};

const fileSlice = createSlice({
  name: 'file',
  initialState,
  reducers: {
    //openModal
    fileScanDetailLoading: (state, action) => {
      state.isLoading = true;
    },
    fileScanDetailAcsess: (state, action) => {
      state.fileScanDetail = action.payload;
      state.isLoading = false;
    },
    fileScanDetailFair: (state, action) => {
      state.isLoading = false;
    }
  }
});

export const { fileScanDetailLoading, fileScanDetailAcsess, fileScanDetailFair } = fileSlice.actions;

export default fileSlice.reducer;
