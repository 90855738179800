import { AbilityBuilder, createMongoAbility } from '@casl/ability';

const defineAbility = () => {
  const { can, cannot, build } = new AbilityBuilder(createMongoAbility);

  return build();
};

export const ability = defineAbility();

export function updateAbility(ability, roles) {
  const { can, rules } = new AbilityBuilder(createMongoAbility);
  // console.log('roles', roles);
  roles.forEach((role) => {
    can('view', 'public');
    if (role === 'ADMIN') {
      can('create', 'Department');
      can('update', 'Department'); // read-write access to everything
      can('delete', 'Department');
      can('view', 'Department');
      can('view', 'Organization');
      can('create', 'Device');
      can('update', 'Device'); // read-write access to everything
      can('delete', 'Device');
      can('create', 'User');
      can('update', 'User'); // read-write access to everything
      can('delete', 'User');
      can('view', 'User');
      can('create', 'Devices');
      can('update', 'Devices'); // read-write access to everything
      can('delete', 'Devices');
      can('view', 'Devices');
      can('create', 'Receipt');
      can('update', 'Receipt'); // read-write access to everything
      can('delete', 'Receipt');
      can('add', 'Receipt');
      //export
      can('create', 'Export');
      can('update', 'Export'); // read-write access to everything
      can('delete', 'Export');
      can('add', 'Export');
      //inventory
      can('create', 'Inventory');
      can('export', 'Inventory');
      //sibar
      can('view', 'Receipt');
      can('view', 'Export');
      can('view', 'Inventory');
      //sibar
      can('view', 'GroupCategory');
      can('view', 'GroupInventory');
      can('view', 'GroupDevicesDepartment');
      can('view', 'GroupUpload');
      //deviceDepartment
      can('view', 'DeviceDepartment');
      can('create', 'DeviceHistory');
      can('update', 'DeviceHistory');
      can('view', 'requestExport');
    }

    if (role === 'ROLE_USER') {
      // can('create', 'Department');
      // can('update', 'Department'); // read-write access to everything
      // can('delete', 'Department');
      // can('create', 'Device');
      // can('update', 'Device'); // read-write access to everything
      // can('delete', 'Device');
      // can('create', 'User');
      // can('update', 'User'); // read-write access to everything
      // can('delete', 'User');
      // can('create', 'Devices');
      // can('update', 'Devices'); // read-write access to everything
      // can('delete', 'Devices');
      // can('create', 'Receipt');
      // can('update', 'Receipt'); // read-write access to everything
      // can('delete', 'Receipt');
      // can('add', 'Receipt');
      // //devie history
      // can('create', 'DeviceHistory');
      // can('update', 'DeviceHistory'); // read-write access to everything
      // can('delete', 'DeviceHistory');
      // can('view', 'Aprroved');
      can('view', 'public');
      can('view', 'GroupUpload');
      //Inventory
      // can('view', 'Inventory');
    }

    if (role === 'ROLE_KE_TOAN_KHO') {
      // can('create', 'Department');
      // can('update', 'Department'); // read-write access to everything
      // can('delete', 'Department');
      // can('view', 'Department');
      can('create', 'Device');
      can('update', 'Device'); // read-write access to everything
      can('delete', 'Device');
      //user
      // can('create', 'User');
      // can('update', 'User'); // read-write access to everything
      // can('delete', 'User');
      // can('view', 'User');
      //category
      can('view', 'GroupCategory');
      can('create', 'Devices');
      can('update', 'Devices'); // read-write access to everything
      can('delete', 'Devices');
      can('view', 'Devices');
      can('create', 'Receipt');
      can('update', 'Receipt'); // read-write access to everything
      can('delete', 'Receipt');
      can('add', 'Receipt');
      //export
      can('create', 'Export');
      can('update', 'Export'); // read-write access to everything
      can('delete', 'Export');
      can('add', 'Export');
      //inventory
      can('create', 'Inventory');
      can('export', 'Inventory');
      //sibar
      can('view', 'Receipt');
      can('view', 'Export');
      can('view', 'Inventory');
      can('view', 'GroupInventory');
      //deviceDepartment
      // can('view', 'DeviceDepartment');
      // can('view', 'requestExport');
      //file
      can('view', 'GroupUpload');
    }

    if (role === 'ROLE_CAN_BO_QTHT') {
      can('create', 'Department');
      can('update', 'Department'); // read-write access to everything
      can('delete', 'Department');
      can('view', 'Department');
      can('view', 'Organization');
      can('create', 'Device');

      can('delete', 'Device');
      // can('create', 'User');
      can('update', 'User'); // read-write access to everything
      can('delete', 'User');
      can('view', 'User');
      can('create', 'Devices');
      can('update', 'Devices'); // read-write access to everything
      can('delete', 'Devices');
      can('view', 'Devices');

      //warehouse
      can('view', 'GroupInventory');

      can('view', 'Inventory');
      can('view', 'Group');
      //category
      can('view', 'GroupCategory');

      //deviceDepartment
      can('view', 'GroupDevicesDepartment');
      can('create', 'DeviceHistory');
      can('update', 'DeviceHistory');
      can('view', 'DeviceDepartment');
      can('view', 'requestExport');
      //file
      can('view', 'GroupUpload');
      can('view', 'public');
    }

    // 	if (role === 'ROLE_CAN_BO_QTHT') {
    // 		can('create', 'Department');
    // 		can('update', 'Department'); // read-write access to everything
    // 		can('delete', 'Department');
    // 		can('view', 'Department');
    // 		can('create', 'Device');
    // 		can('update', 'Device'); // read-write access to everything
    // 		can('delete', 'Device');
    // 		can('create', 'User');
    // 		can('update', 'User'); // read-write access to everything
    // 		can('delete', 'User');
    // 		can('view', 'User');
    // 		can('create', 'Devices');
    // 		can('update', 'Devices'); // read-write access to everything
    // 		can('delete', 'Devices');
    // 		can('view', 'Devices');
    // 		can('create', 'Receipt');
    // 		can('update', 'Receipt'); // read-write access to everything
    // 		can('delete', 'Receipt');
    // 		can('add', 'Receipt');
    // 		//export
    // 		can('create', 'Export');
    // 		can('update', 'Export'); // read-write access to everything
    // 		can('delete', 'Export');
    // 		can('add', 'Export');
    // 		//inventory
    // 		can('create', 'Inventory');
    // 		can('export', 'Inventory');
    // 		//sibar
    // 		can('view', 'Receipt');
    // 		can('view', 'Export');
    // 		can('view', 'Inventory');
    // 		can('view', 'Group');
    // 		//deviceDepartment
    // 		can('view', 'DeviceDepartment');
    // 		can('view', 'requestExport');
    // 	}
  });

  ability.update(rules);
  // console.log('rules', ability);
}
